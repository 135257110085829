import { BASE } from "@/api/index";
import axios from "@/api/http";

const BASE_URL = BASE.PRO1;

/**
 * 总控-专区-首页全局配置-列表
 * @param {*} params
 * @returns
 */
export function postRegionConfigList(params = {}) {
  return axios.post(`${BASE_URL}/region/config/list`, params);
}

/**
 * 总控-专区-首页全局配置-编辑（启用/禁用）
 * @param {*} params
 * @returns
 */
export function postRegionConfigEdit(params = {}) {
  return axios.post(`${BASE_URL}/region/config/edit`, params);
}

/**
 * 总控-专区-首页全局配置-添加
 * @param {*} params
 * @returns
 */
export function postRegionConfigAdd(params = {}) {
  return axios.post(`${BASE_URL}/region/config/add`, params);
}

/**
 * 总控-添加/编辑/筛选所需配置数据
 * @param {*} params
 * @returns
 */
export function postRegionSelectConfig(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/selectConfig`, params);
}

/**
 * 总控-专区-列表
 * @param {*} params
 * @returns
 */
export function postVersatileRegionList(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/list`, params);
}

/**
 * 总控-专区-添加
 * @param {*} params
 * @returns
 */
export function postVersatileRegionAdd(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/add`, params);
}

/**
 * 总控-专区-编辑
 * @param {*} params
 * @returns
 */
export function postVersatileRegionEdit(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/edit`, params);
}

/**
 * 总控-专区-删除
 * @param {*} params
 * @returns
 */
export function postVersatileRegionDelete(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/delete`, params);
}

/**
 * 总控-专区-详情
 * @param {*} params
 * @returns
 */
export function postVersatileRegionInfo(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/info`, params);
}

/**
 * 总控-专区-自定义-关联商品列表
 * @param {*} params
 * @returns
 */
export function postVersatileRegionProductList(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/product/list`, params);
}

/**
 * 总控-专区-自定义-关联商品筛选
 * @param {*} params
 * @returns
 */
export function postVersatileRegionProductSearch(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/product/search`, params);
}

/**
 * 总控-专区-自定义-关联商品添加
 * @param {*} params
 * @returns
 */
export function postVersatileRegionProductAdd(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/product/add`, params);
}

/**
 * 总控-专区-自定义-关联商品删除
 * @param {*} params
 * @returns
 */
export function postVersatileRegionProductDelete(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/product/delete`, params);
}

/**
 * 总控-专区-自定义-关联商品导入
 * @param {*} params
 * @returns
 */
export function postVersatileRegionBindProductImport(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/bind/product/import`, params);
}

/**
 * 总控-专区-自定义-关联商品-移除库存为0商品
 * @param {*} params
 * @returns
 */
export function postVersatileRegionBindProductStockRemove(params = {}) {
  return axios.post(
    `${BASE_URL}/versatile/region/bind/product/stock/remove`,
    params
  );
}

/**
 * 总控-专区-自定义-关联商品-排序值
 * @param {*} params
 * @returns
 */
export function postVersatileRegionBindProductSort(params = {}) {
  return axios.post(`${BASE_URL}/versatile/region/bind/product/sort`, params);
}
